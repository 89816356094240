.App .ant-upload-picture-card-wrapper {
  width: fit-content;
}

.App .ant-upload.ant-upload-select-picture-card {
  border: none;
}

.App .ant-upload.ant-upload-drag {
  height: 72px;
  border: none;
}

.App .ant-upload.ant-upload-drag .ant-upload {
  padding: 16px;
  width: 400px;
}

.ant-upload {
  position: relative;
}

.company_upload {
  margin: 0 auto;
}

.inflow_table .ant-table-small .ant-table-title,
.ant-table-small .ant-table-footer,
.ant-table-small .ant-table-thead > tr > th,
.ant-table-small .ant-table-tbody > tr > td {
  padding: 0px !important;
  height: 60px;
  text-align: center;
}

.tracks_like_table .ant-table-cell {
  cursor: pointer;
}

.footer_table .ant-table-thead {
  display: none;
}

/* 가장 자리 (border-radius 침범) 임시 처리 */
.ant-table-wrapper {
  overflow: hidden;
}
