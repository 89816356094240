@font-face {
  font-family: NotoSansCJKkr-Black;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Black.otf')
    format('opentype');
}

@font-face {
  font-family: NotoSansCJKkr-Bold;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Bold.otf')
    format('opentype');
}

@font-face {
  font-family: NotoSansCJKkr-DemiLight;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-DemiLight.otf')
    format('opentype');
}

@font-face {
  font-family: NotoSansCJKkr-Light;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Light.otf')
    format('opentype');
}

@font-face {
  font-family: NotoSansCJKkr-Medium;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Medium.otf')
    format('opentype');
}

@font-face {
  font-family: NotoSansCJKkr-Regular;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Regular.otf')
    format('opentype');
}

@font-face {
  font-family: NotoSansCJKkr-Thin;
  src: url('../resources/fonts/NotoSansCJKkr/NotoSansCJKkr-Thin.otf')
    format('opentype');
}

body {
  font-family: 'NotoSansCJKkr-Regular';
}
