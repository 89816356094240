.modal_outer {
    /* background: #000000cc;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 1; */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

.modal_inner {
    position: relative;
    z-index : 100;
    background-color: #ffffff;
    margin: 3% auto; /* 15% from the top and centered */
    padding: 50px;
    border-radius: 18px;
    width: 50%; /* Could be more or less, depending on screen size */    
}